import React from 'react'
import './form_block.scss';
import { PatternFormat } from 'react-number-format';

class Form_block extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sends: '123'
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        console.log(this.state);
    }

    handlePhoneChange = (values) => {
        const { formattedValue } = values; // Получаем форматированное значение
        this.setState({ phone: formattedValue });
    }

    handleSubmit = (event) => {
        fetch('/send.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        }).then(function (response) {
            document.querySelector('form').style.display = 'none';
            document.querySelector('.remodal .descr').style.display = 'none';
            document.querySelector('.remodal .title_block').textContent = "Спасибо! Свяжемся в ближайшее время!";
            //return response.json();
        });
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={'item'}>
                    <input type={'text'} value={this.state.value} required={true} name={'name'} placeholder={'Ваше имя*'}
                        onChange={this.handleChange} />
                </div>
                <div className={'item'}>
                    {/* <input type={'tel'} value={this.state.value} required={true} name={'phone'} placeholder={'Ваш телефон'}
                        onChange={this.handleChange} /> */}
                    <PatternFormat
                        name='phone'
                        format="+7##########"
                        mask="_"
                        placeholder="Ваш телефон*"
                        required
                        value={this.state.phone}
                        onValueChange={this.handlePhoneChange}
                    />
                </div>
                <div className={'item'}>
                    <input type={'text'} value={this.state.value} required={false} name={'site'} placeholder={'Сайт'}
                        onChange={this.handleChange} />
                </div>
                <div className="modal_check">
                    <input type="checkbox" required={true} className="custom-checkbox" id="get_touch-check" name="consent" />
                    <label htmlFor="get_touch-check">Я согласен на обработку персональных данных*</label>
                </div>
                <div className="btn_sub">
                    <input type={'submit'} value={'отправить'} className={'btn_sub-btn'} />
                </div>
            </form>

        )
    }
}

export default Form_block;