import './Reviews.scss'
import ReviewsBlockquote from './ReviewsBlockquote.jsx/ReviewsBlockquote';

import ReviewsSwiper from "./ReviewsSwiper/ReviewsSwiper";

function Reviews() {
   return (
      <section className="reviews">
         <div className="container">
            <div className="reviews_wrap">
               <ReviewsBlockquote />
               <ReviewsSwiper />
            </div>
         </div>
      </section>
   );
}

export default Reviews;