import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive'
import './ServicePageTabs.scss'

function ServicePageTabs({ activeTab, onTabClick }) {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
   const tabsList = [
      {
         title: 'Target segments',
         id: '1',
         keyId: uuidv4(),
      },
      {
         title: 'Retargeting to contact / call',
         id: '2',
         keyId: uuidv4(),
      },
      {
         title: 'Call center telephony',
         id: '3',
         keyId: uuidv4(),
      },
      {
         title: 'Data Enrichment',
         id: '4',
         keyId: uuidv4(),
      }
   ]

   const options = tabsList.map(item => ({
      value: item.id,
      label: item.title,
   }));

   const handleChange = selectedOption => {
      onTabClick(selectedOption.value);
   };
   return (
      <div className="service_page_tabs">
         {!isMobile ? (
            tabsList.map((item, index) => (
               <button
                  key={item.keyId}
                  type="button"
                  aria-label={item.title}
                  data-id={item.id}
                  className={`service_page_tabs-item ${activeTab === item.id ? 'active' : ''}`}
                  onClick={() => onTabClick(item.id)}
               >
                  {item.title}
               </button>
            ))
         ) : (
            <Select
               options={options}
               isSearchable={false}

               value={options.find(option => option.value === activeTab)} // Устанавливаем текущее значение
               onChange={handleChange} // Обрабатываем выбор
               classNamePrefix="react-select" // Префикс для классов CSS
               aria-label="Select a service tab"
               className='react-select'
            />
         )}
      </div>
   );
}

export default ServicePageTabs;