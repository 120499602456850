import { Link } from 'react-router-dom';
import '../Modals.scss'
import './ModalMenu.scss'
import Modal from 'react-modal';

Modal.setAppElement('#root');

function ModalMenu({ isOpen, onRequestClose }) {

   const scrollToAnchor = (anchorID) => {
      const element = document.querySelector(anchorID);

      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   };

   const handleLinkClick = (anchorID) => {
      onRequestClose();
      setTimeout(() => {
         scrollToAnchor(anchorID);
      }, 850);
   };

   return (
      <Modal
         closeTimeoutMS={800}
         isOpen={isOpen}
         onRequestClose={onRequestClose}
         contentLabel="Product Details"
         className={'modal_menu'}
         overlayClassName={'modal_menu_wrap'}
      >
         <button type='button' aria-label='Зыкрыть меню' className="close_menu" onClick={onRequestClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
               <rect x="0.5" width="26" height="26" rx="13" fill="#F5F5F7" />
               <path d="M10.5 16L16.5 10M16.5 16L10.5 10" stroke="#121212" />
            </svg>
         </button>
         <nav>
            <ul>
               <li>
                  <Link to='/'
                     onClick={() => {
                        onRequestClose()
                        handleLinkClick('#products')
                     }}
                  >
                     Продукты</Link>
               </li>
               <li>
                  <Link to='/services' onClick={onRequestClose}>Стоимость</Link>
               </li>
               <li>
                  <Link to='/partners' onClick={onRequestClose}>Партнерам</Link>
               </li>
               <li>
                  <Link to='/contacts' onClick={onRequestClose}>Контакты</Link>
               </li>
            </ul>
         </nav>
         <div className="modal_menu-circle"></div>
      </Modal>
   );
}
export default ModalMenu;