import React, { useEffect, useState } from 'react'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import FrontPage from './pages/FrontPage';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Privacy from "./pages/Privacy";
import Services from './pages/Services';

import './App.scss';
import Consent from './pages/Consent';
import Contacts from './pages/Contacts';
import Partners from './pages/Partners';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, [pathname]);

    return null;
}

function App() {
    const [activeMenu, setActiveMenu] = useState(false)
    const [overflowActiveMenu, setOverflowActiveMenu] = useState(false)

    return (
        <>
            <div className={`App ${activeMenu ? 'active' : ''} ${overflowActiveMenu ? 'overflow' : ''}`}>
                <Router>
                    <ScrollToTop />
                    <Header activeMenu={setActiveMenu} overflowActiveMenu={setOverflowActiveMenu} />
                    <main>
                        <Routes>
                            <Route path="/" element={<FrontPage />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/consent" element={<Consent />} />
                            <Route path="/contacts" element={<Contacts />} />
                            <Route path="/partners" element={<Partners />} />
                        </Routes>
                    </main >
                    <Footer />
                </Router>
            </div>
        </>
    );
}

export default App;
