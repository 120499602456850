import { useTab } from '../components/ServicePage/ServicePageTabs/TabContext';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ServicePageTabs from '../components/ServicePage/ServicePageTabs/ServicePageTabs';
import '../components/ServicePage/ServicePage.scss'
import '../components/ServicePage/ServicePageItem/ServicePageItem.scss'
import ServicePageItem from '../components/ServicePage/ServicePageItem/ServicePageItem';

gsap.registerPlugin(ScrollTrigger);

function Services() {
   const { activeTab, setActiveTab } = useTab();

   const handleTabClick = (tabId) => {
      setActiveTab(tabId);
   };

   return (
      <div className="services_page">
         <div className="container">
            <div className="services_page_head">
               <h1 className="services_page_head-title">
                  Ценообразование продуктов <br />
                  платформенного решения Reffection
               </h1>
               <ServicePageTabs activeTab={activeTab} onTabClick={handleTabClick} />
               <div className="list_page_tabs">
                  <ServicePageItem tabsID={activeTab} />
               </div>
            </div>
         </div>
      </div >
   );
}

export default Services;