import React, { useEffect, useRef } from 'react'
import Advantages from "../components/FrontPage/Advantages/Advantages";
import BlackBlock from "../components/FrontPage/BlackBlock/BlackBlock";
import DataDriven from "../components/FrontPage/DataDriven/DataDriven";
import FirstBlock from "../components/FrontPage/FirstBlock/FirstBlock";
import ReffectionNumber from "../components/FrontPage/ReffectionNumber/ReffectionNumber";
import Reviews from "../components/FrontPage/Reviews/Reviews";
import Warranty from "../components/FrontPage/Warranty/Warranty";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Products from '../components/FrontPage/Products/Products';

gsap.registerPlugin(ScrollTrigger);

function FrontPage() {
   const wrapSection = useRef(null)

   useEffect(() => {
      const allSection = wrapSection.current.querySelectorAll('section')
      allSection.forEach((item, index) => {
         if (index !== 0) {
            gsap.set(item, {
               opacity: 0,
               y: 50,
            })
            ScrollTrigger.create({
               trigger: item,
               start: "top bottom",
               end: "bottom bottom",
               onEnter: () => {
                  gsap.to(item, {
                     opacity: 1,
                     y: 0,
                     duration: .6,
                     onComplete: () => {
                        item.style.transform = ''
                        item.classList.add('open')
                     }
                  })
               }
            });
         }
      })
   }, []);

   const ContentDataDriven = {
      sectionTitle: 'Data Driven подход <br /> в&nbsp;продуктах платформы Reffection',
      slides: [
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                     <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                     <path d="M25.6667 19H20.3333C19.597 19 19 19.597 19 20.3333V25.6667" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M25.6667 43.0002H20.3333C19.597 43.0002 19 42.4033 19 41.6669V36.3335" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M36.3335 43.0002H41.6669C42.4033 43.0002 43.0002 42.4033 43.0002 41.6669V36.3335" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M36.3335 19H41.6669C42.4033 19 43.0002 19.597 43.0002 20.3333V25.6667" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M25.6 30.9331H36.2667" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M31.0668 25.6001V36.2668" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>`,
            title: 'Сбор данных',
            text: 'Идентифицируем скрытых потребителей в точке касания с продуктом или категорией рекламодателя',
         },
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                     <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                     <path d="M43 19H19L27.4 30.2C27.7895 30.7193 28 31.3509 28 32.0001V43L34 40V32.0001C34 31.3509 34.2106 30.7193 34.6 30.2L43 19Z" stroke="#006AFE" strokeWidth="2" strokeLinejoin="round" />
                  </svg>`,
            title: 'Анализ данных',
            text: 'Наблюдаем за ключевой частью цифрового пути клиента, от интереса, выраженного поведением в цифровой среде и до конверсии',
         },
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                     <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                     <path d="M35.2353 24.7397C35.2353 27.6222 33.1718 29.6396 31.0065 29.6396C28.85 29.6396 26.7778 27.6174 26.7778 24.7664C26.7778 21.9758 28.8314 20 31.0065 20C33.1906 20 35.2353 21.9318 35.2353 24.7397ZM39.2157 42H22.7974C21.8176 42 21.3691 41.8489 21.1826 41.7233C21.0903 41.6611 21 41.5778 21 41.2247C21 39.8808 21.853 38.0884 23.6069 36.5972C25.3318 35.1307 27.8545 34.0289 31.0065 34.0289C34.1515 34.0289 36.6709 35.1304 38.3944 36.5969C40.1469 38.0882 41 39.8807 41 41.2247C41 41.5782 40.9094 41.6622 40.8176 41.7243C40.6328 41.8491 40.188 42 39.2157 42Z" stroke="#006AFE" strokeWidth="2" />
                  </svg>`,
            title: 'Целевая настройка',
            text: ' Истинно персонализированная, возможность таргетировать коммуникацию буквально к каждой целевой персоне',
         },
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                     <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                     <path d="M24.9998 39.0002H34.8398C38.2398 39.0002 40.9998 36.1549 40.9998 32.6497C40.9998 29.1446 38.2398 26.2993 34.8398 26.2993H21.2998" stroke="#006AFE" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M24.14 29.4947L21 26.2371L24.14 23" stroke="#006AFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>`,
            title: 'Оптимизация расходов',
            text: ' Возвращаем оплаченные в процессе привлечения, но “слитые” отделом продаж целевые лиды компетенциями колл-центра',
         },
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                  <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                  <path d="M18.012 20C18.012 19.4477 18.4651 19 19.024 19H43.9878C44.5467 19 44.9998 19.4477 44.9998 20C44.9998 20.5523 44.5467 21 43.9878 21H19.024C18.4651 21 18.012 20.5523 18.012 20ZM18.012 28.6667C18.012 28.1144 18.4651 27.6667 19.024 27.6667H43.9878C44.5467 27.6667 44.9998 28.1144 44.9998 28.6667C44.9998 29.2189 44.5467 29.6667 43.9878 29.6667H19.024C18.4651 29.6667 18.012 29.2189 18.012 28.6667ZM40.9516 37.3333C40.9516 36.7811 41.4047 36.3333 41.9637 36.3333H43.9878C44.5467 36.3333 44.9998 36.7811 44.9998 37.3333C44.9998 37.8856 44.5467 38.3333 43.9878 38.3333H41.9637C41.4047 38.3333 40.9516 37.8856 40.9516 37.3333Z" fill="#006AFE" />
                  <path d="M18.5735 37.3168C18.8578 37.1295 19.1329 36.9167 19.3931 36.6843V41.9999C19.3931 42.5521 19.8462 42.9999 20.4051 42.9999C20.9641 42.9999 21.4172 42.5521 21.4172 41.9999V33.3332C21.4172 32.8079 21.0073 32.3772 20.4862 32.3364C19.9887 32.2957 19.5252 32.6243 19.4156 33.1195C19.2448 33.8911 18.4822 34.9732 17.4508 35.6527C16.9857 35.9591 16.86 36.5799 17.1701 37.0395C17.4801 37.4989 18.1085 37.6231 18.5735 37.3168Z" fill="#006AFE" />
                  <path d="M26.1083 35.6971C26.1083 34.9935 26.7068 34.3335 27.4385 34.3335C27.9879 34.3335 28.4302 34.588 28.644 34.9128C28.8274 35.1914 28.9374 35.6666 28.5178 36.3724C28.316 36.7118 28.011 37.0154 27.5981 37.3376C27.3923 37.4982 27.1723 37.654 26.9329 37.8212L26.8333 37.8907C26.6279 38.0339 26.408 38.1871 26.1956 38.3451C25.1922 39.0912 24.0842 40.1383 24.0842 42.0002C24.0842 42.5524 24.5373 43.0002 25.0963 43.0002H25.1073H25.1183H29.7524C30.3113 43.0002 30.7645 42.5524 30.7645 42.0002C30.7645 41.4479 30.3113 41.0002 29.7524 41.0002H26.3615C26.5785 40.6244 26.9322 40.3004 27.4127 39.9431C27.5982 39.8052 27.7897 39.6716 27.9965 39.5275L28.1012 39.4544C28.3422 39.2862 28.6009 39.1035 28.8526 38.9071C29.3546 38.5154 29.8791 38.031 30.263 37.3855C31.0145 36.1216 31.0002 34.8242 30.3408 33.8223C29.7117 32.8668 28.5925 32.3335 27.4385 32.3335C25.4447 32.3335 24.0842 34.0371 24.0842 35.6971C24.0842 36.2494 24.5373 36.6971 25.0963 36.6971C25.6552 36.6971 26.1083 36.2494 26.1083 35.6971Z" fill="#006AFE" />
                  <path d="M34.8405 35.1838C34.8837 35.0342 34.9866 34.8223 35.179 34.6547C35.3523 34.5036 35.6588 34.3335 36.2287 34.3335C37.256 34.3335 37.5781 35.0004 37.5781 35.3032C37.5781 35.63 37.5076 35.9572 37.322 36.1878C37.1718 36.3746 36.8111 36.6668 35.8351 36.6668C35.2762 36.6668 34.8231 37.1146 34.8231 37.6668C34.8231 38.2191 35.2762 38.6668 35.8351 38.6668C36.2042 38.6668 36.7141 38.7154 37.096 38.8923C37.274 38.9747 37.3854 39.0675 37.4529 39.1611C37.5134 39.2451 37.5781 39.3828 37.5781 39.6366C37.5781 40.2868 37.3728 40.5744 37.1995 40.719C36.9956 40.8891 36.6659 41.0002 36.2287 41.0002C35.7403 41.0002 35.4638 40.9167 35.2943 40.8106C35.144 40.7166 34.9708 40.5386 34.8286 40.1119C34.654 39.5872 34.0819 39.3019 33.551 39.4746C33.02 39.6471 32.7312 40.2124 32.906 40.737C33.1574 41.492 33.5746 42.1019 34.2115 42.5004C34.829 42.8867 35.5365 43.0002 36.2287 43.0002C36.9723 43.0002 37.8235 42.8159 38.505 42.2472C39.2174 41.6532 39.6022 40.759 39.6022 39.6366C39.6022 39.0038 39.4271 38.4523 39.1013 38.0007C38.9953 37.8535 38.8777 37.7232 38.7533 37.608C38.8068 37.5515 38.8579 37.4928 38.9066 37.4323C39.4975 36.6976 39.6022 35.843 39.6022 35.3032C39.6022 33.8726 38.3501 32.3335 36.2287 32.3335C35.1959 32.3335 34.4052 32.6623 33.84 33.1548C33.2942 33.6306 33.0179 34.2066 32.8941 34.6347C32.7407 35.1658 33.052 35.7192 33.5895 35.8708C34.1269 36.0224 34.6871 35.7148 34.8405 35.1838Z" fill="#006AFE" />
               </svg>`,
            title: 'Измерение результатов',
            text: 'Считаем конверсии на всех этапах цифровой воронки',
         },
         {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                     <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                     <path d="M35.4688 37.4615L38.7019 43H36.5477L33.3147 37.4615H28.6965L25.4635 43H23.3093L26.5424 37.4615H20.7333C20.4858 37.4615 20.2484 37.3643 20.0734 37.1912C19.8983 37.0181 19.8 36.7833 19.8 36.5385V20.8462H17.9333C17.6858 20.8462 17.4484 20.7489 17.2734 20.5758C17.0983 20.4027 17 20.1679 17 19.9231C17 19.6783 17.0983 19.4435 17.2734 19.2704C17.4484 19.0973 17.6858 19 17.9333 19H44.0667C44.3142 19 44.5516 19.0973 44.7266 19.2704C44.9017 19.4435 45 19.6783 45 19.9231C45 20.1679 44.9017 20.4027 44.7266 20.5758C44.5516 20.7489 44.3142 20.8462 44.0667 20.8462H42.2V36.5385C42.2 36.7833 42.1017 37.0181 41.9266 37.1912C41.7516 37.3643 41.5142 37.4615 41.2667 37.4615H35.4688ZM40.3333 20.8462H21.6667V35.6154H40.3333V20.8462ZM26.3333 28.2308C26.5809 28.2308 26.8183 28.328 26.9933 28.5011C27.1683 28.6742 27.2667 28.909 27.2667 29.1538V31C27.2667 31.2448 27.1683 31.4796 26.9933 31.6527C26.8183 31.8258 26.5809 31.9231 26.3333 31.9231C26.0858 31.9231 25.8484 31.8258 25.6734 31.6527C25.4983 31.4796 25.4 31.2448 25.4 31V29.1538C25.4 28.909 25.4983 28.6742 25.6734 28.5011C25.8484 28.328 26.0858 28.2308 26.3333 28.2308ZM31 26.3846C31.2475 26.3846 31.4849 26.4819 31.66 26.655C31.835 26.8281 31.9333 27.0629 31.9333 27.3077V31C31.9333 31.2448 31.835 31.4796 31.66 31.6527C31.4849 31.8258 31.2475 31.9231 31 31.9231C30.7525 31.9231 30.5151 31.8258 30.34 31.6527C30.165 31.4796 30.0667 31.2448 30.0667 31V27.3077C30.0667 27.0629 30.165 26.8281 30.34 26.655C30.5151 26.4819 30.7525 26.3846 31 26.3846ZM35.6667 24.5385C35.9142 24.5385 36.1516 24.6357 36.3266 24.8088C36.5017 24.9819 36.6 25.2167 36.6 25.4615V31C36.6 31.2448 36.5017 31.4796 36.3266 31.6527C36.1516 31.8258 35.9142 31.9231 35.6667 31.9231C35.4191 31.9231 35.1817 31.8258 35.0067 31.6527C34.8317 31.4796 34.7333 31.2448 34.7333 31V25.4615C34.7333 25.2167 34.8317 24.9819 35.0067 24.8088C35.1817 24.6357 35.4191 24.5385 35.6667 24.5385Z" fill="#006AFE" />
                  </svg>`,
            title: 'Прогнозирование',
            text: 'Осуществляем предиктивный скоринг потребителей на основе анонимизированной Big Data, собранной на клиентских данных',
         },
      ]
   }


   return (
      <div className="front_page" ref={wrapSection}>
         <FirstBlock />
         <Advantages />
         <BlackBlock />
         <DataDriven content={ContentDataDriven} isMobileSlider={true} isDesktopSlider={true} />
         <ReffectionNumber />
         <Products />
         <Reviews />
         <Warranty />
      </div>
   );
}

export default FrontPage;