import React, { useEffect, useState, useRef } from 'react'
import CustomText from '../../utilities/CustomText/CustomText';
import BtnButton from '../../utilities/Btn/BtnButton';
import { v4 as uuidv4 } from 'uuid';
import ServicePageTariffs from '../ServicePageTariffs/ServicePageTariffs';

function ServicePageItem({ tabsID }) {
   const [cases, setCases] = useState([])
   const tableRef = useRef(null)
   const maxHeight = 340
   const [loading, setLoading] = useState(true);
   const [showTariffs, setShowTariffs] = useState(false)

   // Иконки для сервисов
   const icons = [
      {
         pay: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
   <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE"/>
   <path d="M25.6667 19H20.3333C19.597 19 19 19.597 19 20.3333V25.6667" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M25.6667 42.9987H20.3333C19.597 42.9987 19 42.4018 19 41.6654V36.332" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M36.334 42.9987H41.6673C42.4037 42.9987 43.0007 42.4018 43.0007 41.6654V36.332" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M36.334 19H41.6673C42.4037 19 43.0007 19.597 43.0007 20.3333V25.6667" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M31 25.668V36.3346" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M36.334 25.668V36.3346" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M25.666 25.668V36.3346" stroke="#006AFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>`,
         method: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
    <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE"/>
    <path d="M31.9229 38.6196C31.8718 38.4967 31.7988 38.3867 31.7058 38.2937L28.7068 35.2947C28.3159 34.9037 27.6839 34.9037 27.2929 35.2947C26.9019 35.6847 26.9019 36.3177 27.2929 36.7086L28.5859 38.0016H26.9999C25.3459 38.0016 23.9999 36.6556 23.9999 35.0017V25.0019C25.1029 25.0019 25.9999 24.1049 25.9999 23.0019V21.0019C25.9999 19.8989 25.1029 19.002 23.9999 19.002H22C20.897 19.002 20 19.8989 20 21.0019V23.0019C20 24.1049 20.897 25.0019 22 25.0019V35.0017C22 37.7587 24.243 40.0017 26.9999 40.0017H28.5859L27.2929 41.2946C26.9019 41.6846 26.9019 42.3176 27.2929 42.7086C27.4879 42.9036 27.7439 43.0016 27.9999 43.0016C28.2559 43.0016 28.5119 42.9036 28.7069 42.7086L31.7059 39.7096C31.7989 39.6166 31.8719 39.5066 31.9229 39.3836C32.0239 39.1396 32.0239 38.8636 31.9229 38.6196Z" fill="#006AFE"/>
    <path d="M39.9986 36.9999V27.0001C39.9986 24.2432 37.7556 22.0002 34.9987 22.0002H33.4127L34.7057 20.7072C35.0966 20.3162 35.0966 19.6842 34.7057 19.2932C34.3147 18.9023 33.6827 18.9023 33.2917 19.2932L30.2927 22.2922C30.1997 22.3852 30.1268 22.4952 30.0758 22.6182C29.9747 22.8622 29.9747 23.1382 30.0758 23.3822C30.1268 23.5052 30.1997 23.6152 30.2927 23.7082L33.2917 26.7072C33.4867 26.9022 33.7427 27.0002 33.9987 27.0002C34.2547 27.0002 34.5107 26.9022 34.7057 26.7072C35.0967 26.3162 35.0967 25.6842 34.7057 25.2932L33.4127 24.0002H34.9987C36.6527 24.0002 37.9987 25.3462 37.9987 27.0002V37C36.8957 37 35.9987 37.897 35.9987 39V41C35.9987 42.103 36.8957 43 37.9987 43H39.9987C41.1017 43 41.9986 42.103 41.9986 41V39C41.9985 37.8969 41.1016 36.9999 39.9986 36.9999Z" fill="#006AFE"/>
  </svg>`,
         script: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
   <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE"/>
   <path d="M33.6812 21.0315C34.1731 21.1628 34.4651 21.6665 34.3333 22.1565L29.4478 40.319C29.3159 40.809 28.8104 41.0998 28.3184 40.9685C27.8265 40.8372 27.5346 40.3334 27.6664 39.8434L32.5519 21.681C32.6837 21.191 33.1892 20.9002 33.6812 21.0315Z" fill="#006AFE"/>
   <path d="M36.4613 24.7689C36.802 24.3919 37.385 24.3613 37.7636 24.7006L39.8998 26.6158C40.8052 27.4274 41.5561 28.1007 42.0718 28.7112C42.614 29.3533 42.9994 30.0431 42.9994 30.8947C42.9994 31.7462 42.614 32.4361 42.0718 33.0781C41.5561 33.6888 40.8052 34.362 39.8998 35.1736L37.7636 37.0887C37.385 37.4281 36.802 37.3975 36.4613 37.0205C36.1206 36.6434 36.1514 36.0627 36.5299 35.7233L38.6156 33.8532C39.5843 32.9849 40.2374 32.3965 40.6604 31.8955C41.0665 31.4147 41.1552 31.1341 41.1552 30.8947C41.1552 30.6553 41.0665 30.3746 40.6604 29.8938C40.2374 29.3929 39.5843 28.8045 38.6156 27.9361L36.5299 26.0661C36.1514 25.7268 36.1206 25.146 36.4613 24.7689Z" fill="#006AFE"/>
   <path d="M25.4695 26.0661C25.848 25.7268 25.8787 25.146 25.538 24.7689C25.1974 24.3919 24.6143 24.3613 24.2358 24.7006L22.0996 26.6158C21.1943 27.4274 20.4433 28.1007 19.9277 28.7112C19.3854 29.3533 19 30.0431 19 30.8947C19 31.7462 19.3854 32.4361 19.9277 33.0781C20.4433 33.6888 21.1943 34.362 22.0996 35.1735L24.2358 37.0887C24.6143 37.4281 25.1974 37.3975 25.538 37.0205C25.8787 36.6434 25.848 36.0627 25.4695 35.7233L23.3838 33.8532C22.4151 32.9849 21.762 32.3965 21.3389 31.8955C20.9329 31.4147 20.8442 31.1341 20.8442 30.8947C20.8442 30.6553 20.9329 30.3746 21.3389 29.8938C21.762 29.3929 22.4151 28.8045 23.3838 27.9361L25.4695 26.0661Z" fill="#006AFE"/>
 </svg>`
      }
   ]
   // Получить json с сервисами
   const fetchCases = async () => {
      try {
         const response = await fetch('/data/services/services.json');
         if (!response.ok) {
            throw new Error('Не удалось отправить запрос');
         }
         const data = await response.json();
         setTimeout(() => {
            setCases(data);
            setLoading(false);

            // Отображение или удаление кнопки при переходе по ссылке
            setTimeout(() => {
               ButtonVisibility();
            }, 100);
         }, 500);
         setTimeout(() => {
            setShowTariffs(true)
         }, 550);
      } catch (error) {
         console.error('Не удалось получить кейсы: ', error);
      } finally {

      }
   };

   useEffect(() => {
      fetchCases();
   }, []);
   // Рскрыть или скрыть таблицу
   const handleClick = (e) => {
      const thisTarget = e.target;
      const thisParentTarget = thisTarget.closest('.services_page_list_item_wrap');
      const thisTable = thisParentTarget.querySelector('.services_page_list_item_table');

      // Получаем span внутри кнопки
      const thisSpan = thisTarget.tagName === 'BUTTON'
         ? thisTarget.querySelector('span')
         : thisTarget.closest('button')?.querySelector('span');

      if (thisTable.classList.contains('active')) {
         thisTable.style.maxHeight = '340px'; // Закрытие таблицы
         thisTable.classList.remove('active');

         // Изменяем текст span при закрытии таблицы
         if (thisSpan) {
            thisSpan.textContent = 'раскрыть таблицу'; // Текст, когда таблица закрыта
         }
      } else {
         thisTable.style.maxHeight = `${thisTable.scrollHeight}px`; // Открытие таблицы на высоту контента
         thisTable.classList.add('active');

         // Изменяем текст span при открытии таблицы
         if (thisSpan) {
            thisSpan.textContent = 'скрыть таблицу'; // Текст, когда таблица открыта
         }
      }
   };
   // Показать или скрыть кнопки
   const ButtonVisibility = () => {
      if (tableRef.current) {
         const thisList = tableRef.current
         const allTables = thisList.querySelectorAll('.services_page_list_item_table')
         allTables.forEach(item => {
            if (item.scrollHeight > maxHeight) {
               const thisWrap = item.closest('.services_page_list_item_wrap')
               thisWrap.querySelector('button').style.display = 'flex';
            } else {
               const thisWrap = item.closest('.services_page_list_item_wrap')
               thisWrap.querySelector('button').style.display = 'none';
            }
         })
      }
   };
   // Обновление кнопок при ресайзе
   useEffect(() => {
      setTimeout(() => {
         ButtonVisibility();
      }, 10);
      window.addEventListener('resize', ButtonVisibility);
      return () => {
         window.removeEventListener('resize', ButtonVisibility);
      };


   }, [cases, tabsID]);

   // Отображение или скрытие секций при смене таба
   useEffect(() => {
      if (tableRef.current) {
         const thisSection = tableRef.current;
         const allSection = Array.from(thisSection.children);

         allSection.forEach(item => {
            const sectionId = item.getAttribute('data-section-id');
            if (sectionId === tabsID) {
               item.classList.remove('hide');
               item.classList.add('show');
            } else {
               item.classList.remove('show');
               item.classList.add('hide');
            }
         });
      }
   }, [tabsID, cases]);

   if (loading) {
      return (
         <div className="loading-indicator">
            <p>Загрузка данных...</p>
         </div>
      );
   }


   return (
      <div className="services_page_wrap_list" ref={tableRef}>
         <>
            {
               cases.map((item, index) => (
                  <div className="services_page_list hide" data-section-id={index + 1} key={index}>
                     {item.map(item => (
                        <div className={`services_page_list_item ${item.colText ? 'col_text' : ''}`} key={uuidv4()}>
                           <div className="row">
                              <div className="col-xl-6 col-md-6">
                                 <div className="services_page_list_item_left">
                                    <h2 className='services_page_list_item_left-title'>
                                       {item.title}
                                    </h2>
                                    <div className="services_page_list_item_left_list">
                                       {item.items.map(service => (
                                          <div className="services_page_list_item_left_list_it" key={uuidv4()}>
                                             <div className="services_page_list_item_left_list_it-icon">
                                                {service.icon === 'pay' &&
                                                   <div dangerouslySetInnerHTML={{ __html: icons[0].pay }} />
                                                }
                                                {service.icon === 'method' &&
                                                   <div dangerouslySetInnerHTML={{ __html: icons[0].method }} />
                                                }
                                                {service.icon === 'script' &&
                                                   <div dangerouslySetInnerHTML={{ __html: icons[0].script }} />
                                                }
                                             </div>
                                             <div className="services_page_list_item_left_list_it_info">
                                                <h3 className="services_page_list_item_left_list_it_info-title">{service.title}</h3>
                                                <CustomText
                                                   className="services_page_list_item_left_list_it_info-text small"
                                                   dangerouslySetInnerHTML={{ __html: service.description }}
                                                />
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                              <div className="offset-xl-1 col-xl-5 col-md-6">
                                 <div className="services_page_list_item_wrap">
                                    <div className="services_page_list_item_table">
                                       <div className="services_page_list_item_table_head">
                                          {item.table.headers.map(title => (
                                             item.table.headers.length > 1 ? (
                                                <div className="services_page_list_item_table_head-it half" key={uuidv4()}>{title}</div>
                                             ) : (
                                                <div className="services_page_list_item_table_head-it full" key={uuidv4()}>{title}</div>
                                             )
                                          ))}
                                       </div>
                                       <div className="services_page_list_item_table_body">
                                          {item.table.rows.map(title => (
                                             <div className="services_page_list_item_table_body-it" key={uuidv4()}>{title}</div>
                                          ))}
                                       </div>
                                    </div>
                                    <BtnButton className='light_gray' onClick={handleClick}>
                                       <span>раскрыть таблицу</span>
                                       <div className="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                             <path d="M0.5 7H14.5" stroke="#121212" />
                                             <path className='second_line' d="M7.5 14L7.5 2.68221e-07" stroke="#121212" />
                                          </svg>
                                       </div>
                                    </BtnButton>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               ))
            }
            {showTariffs && <ServicePageTariffs />}
         </>
      </div>
   );
}

export default ServicePageItem;