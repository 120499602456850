import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import App from './App.js';
import { TabProvider } from './components/ServicePage/ServicePageTabs/TabContext.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TabProvider>
    <App />
  </TabProvider>
);
